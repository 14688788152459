import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import cn from "classnames"

import "./header.scss"

import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
} from "reactstrap"

const Header = ({ siteTitle, logo }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [scrollY, setScrollY] = useState(0)
  const [isVisible, setIsVisible] = useState(false)

  const handleScroll = (event) => {
    if (scrollY <= event.currentTarget.scrollY) {
      setScrollY(event.currentTarget.scrollY)
      setIsVisible(false)
    }

    if (scrollY > event.currentTarget.scrollY || event.currentTarget.scrollY === 0) {
      setIsVisible(true)
      setScrollY(event.currentTarget.scrollY)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  })

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <Navbar
      fixed={"top"}
      dark
      expand="lg"
      className={cn("navbar sticky", { 'is-hidden': !isVisible })}
    >
      <Container>
        <Link to="https://fahr-erlebnis.ch" className="navbar-brand">
          <img
            src={logo}
            alt={`${siteTitle} Logo`}
            className="navbar-logo"
            />
          </Link>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <Link
                  className="nav-link"
                  activeStyle={{ fontWeight: "bold" }}
                  to="https://fahr-erlebnis.ch"
                >
                  Home
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to="https://fahr-erleben.ch" activeStyle={{ fontWeight: "bold" }}
>
                Erlebnisse
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link"
                  activeStyle={{ fontWeight: "bold" }}
                  to="https://fahr-hofladen.ch"
                >
                  Hofladen
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link"
                  activeStyle={{ fontWeight: "bold" }}
                  to="https://fahr-landwirtschaft.ch"
                >
                  Landwirtschaft
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link"
                  activeStyle={{ fontWeight: "bold" }}
                  to="/"
                >
                  Event
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link"
                  activeStyle={{ fontWeight: "bold" }}
                  to="https://fahr-erlebnis.ch/gastronomie"
                >
                  Gastronomie
                </Link>
              </NavItem>
                   <NavItem>
                <Link
                  className="nav-link"
                  activeStyle={{ fontWeight: "bold" }}
                  to="https://fahr-erlebnis.ch/seminar-raum"
                >
                  Seminarräume
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link"
                  activeStyle={{ fontWeight: "bold" }}
                  to="https://fahr-erlebnis.ch/das-team"
                >
                  Über uns
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link"
                  activeStyle={{ fontWeight: "bold" }}
                  to="https://fahr-erlebnis.ch/kontakt"
                >
                  Kontakt
                </Link>
              </NavItem>
              
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
  )
}

export default Header





// // import React, { Component } from "react"
// // import { Link } from "gatsby"
// // import cn from "classnames"

// // import "./header.scss"

// // import {
// //   Container,
// //   Collapse,
// //   Navbar,
// //   NavbarToggler,
// //   Nav,
// //   NavItem,
// // } from "reactstrap"

// // class Header extends Component {
// //   constructor(props) {
// //     super(props)
// //     this.toggle = this.toggle.bind(this)
// //     this.handleScroll = this.handleScroll.bind(this)
// //     this.state = {
// //       isOpen: false,
// //       scrollY: 0,
// //     }
// //   }

// //   handleScroll(event) {
// //     this.setState({ ...this.state, scrollY: event.currentTarget.scrollY })
// //   }

// //   componentDidMount() {
// //     window.addEventListener("scroll", this.handleScroll, true)
// //   }

// //   componentWillUnmount() {
// //     window.removeEventListener("scroll", this.handleScroll)
// //   }

// //   toggle() {
// //     this.setState({
// //       isOpen: !this.state.isOpen,
// //     })
// //   }

// //   render() {
// //     const { siteTitle, logo } = this.props
// //     return (
// //       <Navbar
// //         fixed={"top"}
// //         dark
// //         expand="md"
// //         className={cn("navbar sticky", { 'is-short': this.state.scrollY > 50 })}
// //       >
// //         <Container>
// //           <Link to="/#hero" className="navbar-brand">
// //             <img
// //               src={logo}
// //               alt={`${siteTitle} Logo`}
// //               height="100px"
// //               className={cn("mr-2 navbar-logo", { 'is-hidden': this.state.scrollY > 50 })}
// //               />
// //               {/* {siteTitle} */}
// //             </Link>
// //             <NavbarToggler onClick={this.toggle} />
// //             <Collapse isOpen={this.state.isOpen} navbar>
// //               <Nav className="ml-auto" navbar>
// //                 <NavItem>
// //                   <Link
// //                     className="nav-link"
// //                     activeStyle={{ fontWeight: "bold" }}
// //                     to="/#hero"
// //                   >
// //                     Home
// //                   </Link>
// //                 </NavItem>
// //                 <NavItem>
// //                   <Link className="nav-link" to="/#feature">
// //                     Erlebnisnachmittage
// //                   </Link>
// //                 </NavItem>
// //                 <NavItem>
// //                   <Link
// //                     className="nav-link"
// //                     activeStyle={{ fontWeight: "bold" }}
// //                     to="/services/#services"
// //                   >
// //                     Schule
// //                   </Link>
// //                 </NavItem>
// //                 <NavItem>
// //                   <Link
// //                     className="nav-link"
// //                     activeStyle={{ fontWeight: "bold" }}
// //                     to="/careers/#careers"
// //                   >
// //                     Workshops
// //                   </Link>
// //                 </NavItem>
// //                 <NavItem>
// //                   <Link
// //                     className="nav-link"
// //                     activeStyle={{ fontWeight: "bold" }}
// //                     to="/about/#about"
// //                   >
// //                     Ferienlager
// //                   </Link>
// //                 </NavItem>
// //                 <NavItem>
// //                   <Link
// //                     className="nav-link"
// //                     activeStyle={{ fontWeight: "bold" }}
// //                     to="/contact/#contact"
// //                   >
// //                     Kontakt
// //                   </Link>
// //                 </NavItem>
// //               </Nav>
// //             </Collapse>
// //           </Container>
// //         </Navbar>
// //     )
// //   }
// // }

// // export default Header


// import React, { useEffect, useState } from "react"
// import { Link } from "gatsby"
// import cn from "classnames"

// import "./header.scss"

// import {
//   Container,
//   Collapse,
//   Navbar,
//   NavbarToggler,
//   Nav,
//   NavItem,
// } from "reactstrap"

// const Header = ({ siteTitle, logo }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [scrollY, setScrollY] = useState(0);

//   const handleScroll = (event) => {
//     if (scrollY >= 50 && event.currentTarget.scrollY < 50) {
//       setScrollY(event.currentTarget.scrollY)
//     }

//     if (scrollY < 50 && event.currentTarget.scrollY >= 50) {

//       setScrollY(event.currentTarget.scrollY)
//     }
//   }

//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll)

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     }
//   })

//   const toggle = () => {
//     setIsOpen(!isOpen)
//   }

//   return (
//     <Navbar
//       fixed={"top"}
//       dark
//       expand="md"
//       className={cn("navbar sticky", { 'is-short': scrollY > 50 })}
//     >
//       <Container>
//         <Link to="/#hero" className={cn("navbar-brand", { 'is-hidden': scrollY > 50 })}>
//           <img
//             src={logo}
//             alt={`${siteTitle} Logo`}
//             // height="100px"
//             className={cn("navbar-logo", { 'is-hidden': scrollY > 50 })}
//             />
//           </Link>
//           <NavbarToggler onClick={toggle} />
//           <Collapse isOpen={isOpen} navbar>
//             <Nav className="ml-auto" navbar>
//               <NavItem>
//                 <Link
//                   className="nav-link"
//                   activeStyle={{ fontWeight: "bold" }}
//                   to="/#hero"
//                 >
//                   Home
//                 </Link>
//               </NavItem>
//               <NavItem>
//                 <Link className="nav-link" to="/erlebnisnachmittag">
//                 Erlebnisnachmittage
//                 </Link>
//               </NavItem>
//               <NavItem>
//                 <Link
//                   className="nav-link"
//                   activeStyle={{ fontWeight: "bold" }}
//                   to="/ferienlager"
//                 >
//                   Ferienlager
//                 </Link>
//               </NavItem>
//               <NavItem>
//                 <Link
//                   className="nav-link"
//                   activeStyle={{ fontWeight: "bold" }}
//                   to="/workshops"
//                 >
//                   workshops
//                 </Link>
//               </NavItem>
//               <NavItem>
//                 <Link
//                   className="nav-link"
//                   activeStyle={{ fontWeight: "bold" }}
//                   to="/schule"
//                 >
// Schule                </Link>
//               </NavItem>
//               <NavItem>
//                 <Link
//                   className="nav-link"
//                   activeStyle={{ fontWeight: "bold" }}
//                   to="/contact/#contact"
//                 >
//                   Kontakt
//                 </Link>
//               </NavItem>
//             </Nav>
//           </Collapse>
//         </Container>
//       </Navbar>
//   )
// }

// export default Header


